body {
  font-family: Arial, Geneva, sans-serif;
  margin: 8px;
}

.signature-container {
  display: flex;
  flex-direction: column;
  align-items: left;
}

.checkbox-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 20px;
}

.checkbox-label {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-left: 10px;
}

.signatures {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.signature-html,
.signature-mobile {
  flex: 1;
  margin: 0 10px;
}

.form-control {
  margin: 10px 40px;
  padding: 5px 10px;
}

input[type="checkbox"] {
  margin-right: 10px;
}

button {
  margin-top: 20px;
  padding: 8px 20px;
  cursor: pointer;
}
